import React from "react"
import styled from "styled-components"

import { PostNode } from "../lib/createPages"
import { FadeLink } from "./link"
import constants from "../utils/constants"
import { rhythm } from "../utils/typography"

const StyledLink = styled(FadeLink)`
  box-shadow: none;
  color: ${constants.colors.links};
`

const Title = styled.h3`
  margin-bottom: ${rhythm(1 / 4)};
`

export const TableOfContents = (props: any) => {
  const { posts } = props;
  if (!posts || !posts.length) {
    return null;
  }
  return (
    <div>
    {posts.map(({ node }: { node: PostNode }) => {
      const frontmatter = node!.frontmatter!
      if (frontmatter.sequencing === 0) {
        return
      }
      const fields = node!.fields!
      const slug = fields.slug!
      const excerpt = node!.excerpt!
      const title = frontmatter.title || fields.slug
      return (
        <div key={slug}>
          <Title>
            <StyledLink to={slug}>{title}</StyledLink>
          </Title>
          {/*<small>{frontmatter.date}</small>*/}
          <p
            dangerouslySetInnerHTML={{
              __html: frontmatter.description || excerpt,
            }}
          />
        </div>
      )
    })}
    </div>
  )
}