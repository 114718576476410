import React from "react"
import styled from "styled-components"

import { FadeLink } from "./link"
// import constants from "../utils/constants"

// const StyledLink = styled(FadeLink)`
//   box-shadow: none;
//   color: ${constants.colors.links};
// `

const TagListContainer = styled.ul`
  display: flex;
  justify-content: flex-start;
`

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  padding-left: 10px;
`

const ListItem = styled.li`
  padding-right: 10px;
`

export const TagList = (props: any) => {
  const tags = props.tags as string[];
  if (!tags || !tags.length) {
    return null;
  }
  return (
    <TagListContainer>
      <span>{props.children}</span>
      <List>
        {tags.map((tag, i) => (
          <ListItem key={i}>
            <FadeLink to={`/tags/${tag}`}>{tag}</FadeLink>
          </ListItem>
        ))}
      </List>
    </TagListContainer>
  )
}