import { graphql, PageRendererProps } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import React from "react"
import styled from "styled-components"
import { createGlobalStyle } from "styled-components"
import { Layout } from "../components/layout"
import { FadeLink } from "../components/link"
import { SEO } from "../components/seo"
import { TagList } from "../components/tag-list"
import { TableOfContents } from "../components/toc"
import { Toolbar } from "../components/toolbar"
import { Query, SitePageContext } from "../graphql-types"
import constants from "../utils/constants"
import { rhythm, styledScale } from "../utils/typography"

interface Props extends PageRendererProps {
  pageContext: SitePageContext
  data: Query
}

const Date = styled.p`
  display: block;
  ${styledScale(-1 / 5)};
  margin-bottom: ${rhythm(1)};
  margin-top: ${rhythm(-1)};
`

const Divider = styled.hr`
  margin-bottom: ${rhythm(1)};
`

const PostRelativeNavLink = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  list-style: none;
  padding: 0;
`

const PostNavLink = styled.ul`
  list-style: none;
  padding: 0;
`

const StyledLink = styled(FadeLink)`
  box-shadow: none;
  color: ${constants.colors.links};
  font-weight: bold;
`

// For MDXProvider. See https://www.gatsbyjs.com/plugins/gatsby-plugin-mdx/
// Applies only to post.body
// Since this BlogPostTemplate is instantiated for each page (via createPages), these components could differ per page,
// possibly dependent on something defined in the frontmatter or implicitly via a tag or something like that TODO
const mdxProviderComponents = {
  h1: (props: any) => <h1 style={{ color: constants.colors.cyan }} {...props} />,
  h2: (props: any) => <h2 style={{ color: constants.colors.chartreuse }} {...props} />,
  h3: (props: any) => <h3 style={{ color: constants.colors.yellow }} {...props} />,
}

const BlogPostTemplate = (props: Props) => {
  const data = props.data!
  const post = data.mdx!
  const excerpt = post.excerpt!
  const frontmatter = post.frontmatter!
  const { tags } = frontmatter
  const siteTitle = data.site!.siteMetadata!.title!
  const { debug, previous, next, slug } = props.pageContext
  const allPosts = data.allMdx!.edges;
  const postsInHere = allPosts.filter(p => {
    const postSlug = p.node!.fields!.slug!
    return (
      postSlug.startsWith(slug!) &&
      postSlug !== slug &&
      postSlug.split('/').length - slug!.split('/').length <= 1
    )})
  const GlobalStyle = createGlobalStyle`
    body {
      background-color: ${frontmatter.backgroundColor || constants.colors.background};
      color: white;
    }
  `
  const havePrevLink = previous && previous.fields && previous.fields!.slug
  const haveNextLink = next && next.fields && next.fields!.slug
  const haveRelativeNavLinks = (havePrevLink || haveNextLink) && slug && slug.split('/').length > 1
  return (
    <Layout location={props.location} slug={slug || ''} title={siteTitle}>
      <GlobalStyle />
      <SEO
        title={frontmatter.title!}
        description={frontmatter.description || excerpt}
      />
      <Toolbar />
      <h1>{post.frontmatter!.title}</h1>
      {debug ? (<span>{debug}</span>) : null}
      {/*<Date>{frontmatter.date}</Date>*/}
      {tags && tags.length && tags[0] ? (
        <TagList tags={tags}>
          Tagged:
        </TagList>
      ) : null}
      <MDXProvider components={mdxProviderComponents}>
        <MDXRenderer
          localImages={frontmatter.embeddedImagesLocal}
        >
          {post.body}
        </MDXRenderer>
      </MDXProvider>
      <Divider />
      {postsInHere.length ? (
          <TableOfContents posts={postsInHere} />
      ) : (
          <TableOfContents />
      )}
      {haveRelativeNavLinks ? (
        <PostRelativeNavLink>
          <li>
            {havePrevLink && (
              <FadeLink to={previous.fields!.slug!} rel="prev">
                ← {previous.frontmatter!.title}
              </FadeLink>
            )}
          </li>
            {haveNextLink! && (
              <li>
                <FadeLink to={next.fields!.slug!} rel="next">
                  {next.frontmatter!.title} →
                </FadeLink>
              </li>
            )}
        </PostRelativeNavLink>
      ) : ('')}
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      body
      frontmatter {
        backgroundColor
        date(formatString: "MMMM DD, YYYY")
        description
        sequencing
        tags
        title
        embeddedImagesLocal {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
    allMdx(sort: { fields: [frontmatter___sequencing], order: ASC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            backgroundColor
            date(formatString: "MMMM DD, YYYY")
            sequencing
            tags
            title
            description
            embeddedImagesLocal {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
`
